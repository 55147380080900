import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B">
      <text x="50%" y="55%" font-size="60" transform="translate(50%)" dominant-baseline="middle" text-anchor="middle" font-weight="bold" fill="var(--cyber_light)" font-family="Calibre">R</text>
      </g>
      
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        dominant-baseline="middle"
        text-anchor="middle"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
    
  </svg>
);

export default IconLoader;
