import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>ramuel.ca</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
          dominant-baseline="middle" 
          text-anchor="middle"
        />
      <text x="47%" y="50%" font-weight="bold" transform="translate(50%)" font-size="60" dominant-baseline="middle" text-anchor="middle" fill="var(--cyber_light)" font-family="Calibre">R</text>
        {/* <path
          d=""
          fill="currentColor"

        /> */}
        
      </g>
    </g>
  </svg>
);

export default IconLogo;
